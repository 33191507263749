import React, { useState } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import moment from "moment";
import { jsPDF } from "jspdf";
// PNG Utils
import html2canvas from "html2canvas";

const TH_STYLE = "border border-white p-2";
const FORM_GRID_STYLE = "grid grid-cols-1 md:grid-cols-2 md:gap-4";

export const Details = ({ parsedData, formId }) => {

  console.log(parsedData, 'parsedData');
  console.log(formId, 'formId');

  const matchingData = parsedData.filter((data) => data.lead_id === formId);
  console.log(matchingData[0], "matchingData");
  // const [formData, setFormData] = useState(matchingData);
  let formData = matchingData[0];

  // Utils for downloadin the CSV
  const captureAndDownload = () => {
    const element = document.getElementById("download-id"); // ID of the element to capture
    html2canvas(element).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = `FormDetails_${moment().format(
        "YYYY-MM-DD HH:mm:ss"
      )}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const captureAndDownloadPDF = () => {
    const element = document.getElementById("download-id");
    html2canvas(element).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");  // Set orientation, units, and size
      const imgWidth = 210; // PDF page width in mm
      const pageHeight = 297; // PDF page height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Scale the image to fit
      let position = 0; // Start position for the image

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight); // Add image to PDF

      pdf.save(`FormDetails_${moment().format("YYYY-MM-DD_HH-mm-ss")}.pdf`);  // Download PDF
    });
  };

  return (
    <div id="download-id" className="p-3">
      <div className="flex">
        <h1 className="text-2xl m-1 p-1 font-bold text-[#166994]">FormDetails</h1>
        {/* <button
          onClick={() => captureAndDownload()}
          className="text-blue-500 text-2xl ml-3"
        >
          <MdOutlineFileDownload />
        </button> */}
        <button
          onClick={() => captureAndDownloadPDF()}
          className="text-blue-500 text-2xl ml-3"
        >
          <MdOutlineFileDownload />
        </button>
      </div>

      <div>

        <div className="text-center container mx-auto h-full w-full">
          <div className="bg-white p-4 rounded-lg max-h-4/5 mx-auto">
            <div className="bg-white">
              <div className="w-full mx-auto">
                {formData && (
                  <div>
                    <div
                      key={formData.lead_id}
                      className={
                        FORM_GRID_STYLE +
                        " grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 "
                      }
                    >
                      <div class="mb-2">
                        <label
                          for="base-input"
                          class="block mb-2 text-left  font-medium text-gray-900 dark:text-black"
                        >
                          Client Name
                        </label>

                        <label
                          className="text-left p-3 bg-gray-100 border text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          {formData.clientname}</label>
                      </div>
                      <div class="mb-2">
                        <label
                          for="base-input"
                          class="block mb-2 text-left  font-medium text-gray-900 dark:text-black"
                        >
                          Principal Investigator
                        </label>

                        <label
                          className="text-left p-3 bg-gray-100 border text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          {formData.principal_investigator}
                        </label>
                      </div>
                      <div class="mb-2">
                        <label
                          for="base-input"
                          class="block mb-2 text-left  font-medium text-gray-900 dark:text-black"
                        >
                          Contact Number
                        </label>

                        <label
                          className="text-left p-3 bg-gray-100 border text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >{formData.contact_no}</label>
                      </div>
                      <div class="mb-2 fle">
                        <label
                          for="base-input"
                          class="block mb-2 text-left  font-medium text-gray-900 dark:text-black"
                        >
                          Email
                        </label>

                        <label
                          className="text-left p-3 bg-gray-100 border text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          {formData.email_id}</label>
                      </div>

                      <div class="mb-2">
                        <label
                          for="base-input"
                          class="block mb-2 text-left  font-medium text-gray-900 dark:text-black"
                        >
                          Billing Name
                        </label>


                        <label
                          className="text-left p-3 bg-gray-100 border text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          {formData.billing_name}</label>
                      </div>
                      <div class="mb-2">
                        <label
                          for="base-input"
                          class="block mb-2 text-left  font-medium text-gray-900 dark:text-black"
                        >
                          Institute Name
                        </label>

                        <label
                          className="text-left p-3 bg-gray-100 border text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          {formData.institute_name}</label>
                      </div>
                      <div class="mb-2">
                        <label
                          for="base-input"
                          class="block mb-2 text-left  font-medium text-gray-900 dark:text-black"
                        >
                          Enter Pincode
                        </label>

                        <label
                          className="text-left p-3 bg-gray-100 border text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          {formData.enter_pincode}</label>
                      </div>
                      <div class="mb-2">
                        <label
                          for="base-input"
                          class="block mb-2 text-left  font-medium text-gray-900 dark:text-black"
                        >
                          Institute Address
                        </label>

                        <label
                          className="text-left p-3 bg-gray-100 border text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          {formData.institute_address}</label>
                      </div>

                      <div class="mb-2">
                        <label
                          for="base-input"
                          class="block mb-2 text-left  font-medium text-gray-900 dark:text-black"
                        >
                          Other Instructions
                        </label>

                        <label
                          className="text-left p-3 bg-gray-100 border text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          {formData.other_instructions}</label>
                      </div>

                      <div class="mb-2">
                        <label
                          for="base-input"
                          class="block mb-2 text-left  font-medium text-gray-900 dark:text-black"
                        >
                          Analysis Report
                        </label>

                        <label
                          className="text-left p-3 bg-gray-100 border text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          {formData.analysis_report}
                        </label>
                      </div>

                      <div class="mb-2">
                        <label
                          for="base-input"
                          class="block mb-2 text-left  font-medium text-gray-900 dark:text-black"
                        >
                          Samples Submitted
                        </label>

                        <label
                          className="text-left p-3 bg-gray-100 border text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          {formData.samples_submitted}
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* samples */}
      <div className="mt-5">
        <table
          class=" table-repsonsive table table-striped table-bordered display"
          id="item_table"
        >
          <tr className="bg-[#166994] text-white border font-bold">
            <td></td>
            <td colspan="7" className="text-center border border-white p-2">
              Sample Summary
            </td>
            <td colspan="6" className="text-center border border-white p-2">
              Primer Information
            </td>
          </tr>

          <tr className="bg-[#166994] text-white">
            <th className={TH_STYLE}>
              Sample Name(Code)(Same code should be lablled on sample plate){" "}
            </th>
            <th className={TH_STYLE}>Form of Sample</th>
            <th className={TH_STYLE}>Source of Sample</th>
            <th className={TH_STYLE}>Conc(ng/μL)</th>
            <th className={TH_STYLE}>Insert/Product Length (bp)</th>
            <th className={TH_STYLE}>Vector Name(for plasmid)</th>
            <th className={TH_STYLE}>Amount of Sample(μL)</th>
            <th className={TH_STYLE}>Sequencing</th>
            <th className={TH_STYLE}>Farward Primer</th>
            <th className={TH_STYLE}>Reverse Primer</th>
            <th className={TH_STYLE}>Conc(pmol/μL)</th>
            <th className={TH_STYLE}>Ta(°C)</th>
            <th className={TH_STYLE}>Vol(μL)</th>
          </tr>
          {matchingData.map((primer, sampleIndex) => (
            <React.Fragment key={sampleIndex}>
              {primer.samples.map((sample, primerIndex) => (
                <tr key={`${primerIndex}-${sampleIndex}`}>
                  <td className="p-2">
                    <label
                      className="text-left p-3 bg-gray-100 border text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >{sample.volUl}</label>
                  </td>
                  <td className="p-2">
                    <label
                      className="text-left p-3 bg-gray-100 border text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >{sample.sampleName}</label>
                  </td>

                  <td className="p-2">

                    <label
                      className="text-left p-3 bg-gray-100 border text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >{sample.sequencing}</label>
                  </td>
                  <td className="p-2">

                    <label
                      className="text-left p-3 bg-gray-100 border text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >{sample.taDegreesC}</label>
                  </td>

                  <td className="p-2">
                    <label
                      className="text-left p-3 bg-gray-100 border text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >{sample.vectorName}</label>
                  </td>

                  <td className="p-2">
                    <label
                      className="text-left p-3 bg-gray-100 border text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >{sample.concNgPerUl}</label>
                  </td>
                  <td className="p-2">
                    <label
                      className="text-left p-3 bg-gray-100 border text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >{sample.formOfSample}</label>
                  </td>

                  <td className="p-2">
                    <label
                      className="text-left p-3 bg-gray-100 border text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >{sample.concPmolPerUl}</label>
                  </td>

                  <td className="p-2">
                    <label
                      className="text-left p-3 bg-gray-100 border text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >{sample.forwardPrimer}</label>
                  </td>


                  <td className="p-2">
                    <label
                      className="text-left p-3 bg-gray-100 border text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >{sample.reversePrimer}</label>
                  </td>

                  <td className="p-2">
                    <label
                      className="text-left p-3 bg-gray-100 border text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >{sample.insertLengthBp}</label>
                  </td>
                  <td className="p-2">

                    <label
                      className="text-left p-3 bg-gray-100 border text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >{sample.sourceOfSample}</label>
                  </td>

                  <td className="p-2">

                    <label
                      className="text-left p-3 bg-gray-100 border text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >{sample.amountOfSampleUl}</label>
                  </td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </table>
      </div>

      {/* primers */}
      <div className="mt-4 p-5 mx-auto overflow-x-auto">
        <table className="table-auto w-full border border-gray-300">
          <thead className="bg-gray-200">
            <tr>
              <th className="p-2">Primer Name</th>
              <th className="p-2">5' --Sequence-- 3'</th>
              <th className="p-2">Primer to be Synthesized (Yes/No)</th>
            </tr>
          </thead>
          <tbody>
            {matchingData.map((sample, sampleIndex) => (
              <React.Fragment key={sampleIndex}>
                {sample.primers.map((primer, primerIndex) => (
                  <tr key={`${sampleIndex}-${primerIndex}`}>
                    <td className="p-2">

                      <label
                        className="text-left p-3 bg-gray-100 border text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >{primer.PrimerName}</label>
                    </td>
                    <td className="p-2">

                      <label
                        className="text-left p-3 bg-gray-100 border text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >{primer.Sequence}</label>
                    </td>
                    <td className="p-2">

                      <label
                        className="text-left p-3 bg-gray-100 border text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >{primer.Synthesized}</label>
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div >
  );
};
