import client from "./client";

export const signIn = async (obj) => {
    try {
        const { data } = await client.post("/auth/login", obj);
        return data;
    } catch (error) {
        const { response } = error;
        if (response?.data) {
            return response.data;
        }
        return { error: error.message || error };
    }
};
