import React from "react";
import logo from "../logo.jpg";
import { FaPhone, FaEnvelope } from "react-icons/fa";

const Header = () => {
  return (
    <div>
      <section className="p-3 bg-blue-600 items-center py-2 md:flex hidden">
        <div className="container mx-auto">
          <div className="flex justify-between">
            <div className="flex gap-4 items-center md:flex hidden">
              <ul className="p-3 flex gap-4 text-white">
                <li className="flex">
                  <FaPhone /> <p className="mx-2 -mt-1">+91 98243 72152</p>
                </li>
                <li className="flex">
                  <FaEnvelope />
                  <p className="mx-2 -mt-1"> info@slsresearch.com</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className="container mx-auto py-2 sticky top-0 z-50 bg-white text-center">
        <a href="#">
          <img src={logo} className="h-10 w-auto object-contain" alt="img" />
        </a>
      </div>
    </div>
  );
};

export default Header;
