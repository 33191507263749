import React, { useEffect, useState } from "react";

import Footer from "./Footer";
import Form1 from "./Form1";
import Form2 from "./Form2";
import Additional from "./Additional";

const Form = () => {
  return (
    <div className="w-full">
      <>
        <div className="py-4 bg-gray-100">
          <h4 className="text-center text-xl font-semibold">
            SEQUENCING SAMPLE SUBMISSION FORM
          </h4>
        </div>
        <Form1 />
        <Form2 />
        <Additional />
        <Footer />
      </>
    </div>
  );
};

export default Form;
