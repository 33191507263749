import client from "./client";

export const getData = async () => {
    try {
        const { data } = await client.get(`/leads/getAllData`);
        return data;
    } catch (error) {
        const { response } = error;
        if (response?.data) {
            return response.data;
        }
        return { error: error.message || error };
    }
};


export const getSingleData = async (id) => {
    try {
        const { data } = await client.get(`/leads/getData/${id}`);
        return data;
    } catch (error) {
        const { response } = error;
        if (response?.data) {
            return response.data;
        }
        return { error: error.message || error };
    }
};

export const deleteRecord = async (id) => {
    try {
        const { data } = await client.delete(`/leads/deleteData/${id}`);
        return data;
    } catch (error) {
        const { response } = error;
        if (response?.data) {
            return response.data;
        }
        return { error: error.message || error };
    }
};

export const updateRecords = async (id, obj) => {
    try {
        const { data } = await client.put(`/leads/updateData/${id}`, obj);
        return data;
    } catch (error) {
        const { response } = error;
        if (response?.data) {
            return response.data;
        }
        return { error: error.message || error };
    }
};

