import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Routes,
} from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import Form from "./Form/Form";
import { Toaster } from "react-hot-toast";

import Dashboard from "./Dashboard/Dashboard";
import Login from "./Authentication/Login";

import { FormProvider } from "./Context/FormContext";
import Header from "./Form/Header";

function App() {
  return (
    <FormProvider>
      <Toaster />
      <Header />
      <Router>
        <Routes initialRoutesName="/">
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/form" element={<Form />} />
        </Routes>
      </Router>
    </FormProvider>
  );
}

export default App;
