import React, { useEffect, useState, useContext } from "react";
import Sample from "./Sample";
import { FormContext } from "../Context/FormContext";
import Primer from "./Primer";
import { toast, Toaster } from "react-hot-toast";



import { FaDownload } from "react-icons/fa6";
import csvSample from "./SLS.xls";

const TH_STYLE = "border border-white p-2";

const Form1 = () => {
  const formContext = useContext(FormContext);
  const { formData, setFormData } = formContext;

  const [primers, setPrimers] = useState([]);
  const [noOfSample, setNoOfSample] = useState(0);

  const addPrimer = () => {
    setPrimers([...primers, createEmptyPrimer()]);
  };

  const deletePrimer = (index) => {
    const updatedPrimers = primers.filter((_, i) => i !== index);
    setPrimers(updatedPrimers);
  };

  const handleChangePrimer = (event, index) => {
    const { name, value } = event.target;
    const updatedPrimer = { ...primers[index], [name]: value };
    const updatedPrimers = [...primers];
    updatedPrimers[index] = updatedPrimer;
    setPrimers(updatedPrimers);
    updateFormData(updatedPrimers);
  };

  const updateFormData = (updatedPrimers) => {
    setFormData({ ...formData, primers: updatedPrimers });
  };

  const createEmptyPrimer = () => ({
    primerName: "",
    sequence: "",
    toBeSynthesized: "No",
  });

  const handleSelectChange = (e) => {
    const selectedNoOfSample = parseInt(e.target.value);
    setNoOfSample(selectedNoOfSample);
    const newSamples = [...formData.samples];

    // Remove extra samples if reducing the count
    if (selectedNoOfSample < newSamples.length) {
      newSamples.splice(selectedNoOfSample);
    } else {
      // Add empty samples if increasing the count
      for (let i = newSamples.length; i < selectedNoOfSample; i++) {
        newSamples.push({
          sampleName: "",
          formOfSample: "",
          sourceOfSample: "",
          concNgPerUl: "",
          insertLengthBp: "",
          vectorName: "",
          amountOfSampleUl: "",
          sequencing: "yes",
          forwardPrimer: "",
          reversePrimer: "",
          concPmolPerUl: "",
          taDegreesC: "",
          volUl: "",
        });
      }
    }

    setFormData({ ...formData, samples: newSamples });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;


    if (name === "contact_no") {
      if (value !== "" && !/^[0-9]+$/.test(value)) {
        toast.error("Please Enter Only Numbers for Contact Number.");
        return;
      }
      if (value.length > 10) {
        toast.error("Please Enter 10 digit number for Contact Number.");
        return;
      }
    }




    //write code to enter only 8 digit number for pincode
    if (name === "enter_pincode") {
      if (value !== "" && !/^[0-9]+$/.test(value)) {
        toast.error("Please Enter Only Numbers for ZIP.");
        return;
      }
      if (value.length > 6) {
        toast.error("Please Enter 6 digit number for ZIP.");
        return;
      }
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const handleSampleChange = (index, newSampleData) => {
    const updatedSamples = [...formData.samples];
    updatedSamples[index] = newSampleData;
    setFormData({ ...formData, samples: updatedSamples });
  };
  return (
    <div>
      <div className="p-5">
        <form onSubmit={handleSubmit} className="">
          <div className="bg-white rounded px-8 pt-6 pb-8 mb-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="flex">
              <input
                className="shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="clientname"
                type="text"
                placeholder="CLIENT NAME"
                name="clientname"
                value={formData.clientname}
                onChange={handleChange}
                required
              />
              <span className="ml-2 text-red-500">*</span>
            </div>
            <div className="flex">
              <input
                className="shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="principal_investigator"
                type="text"
                placeholder="PRINCIPAL INVESTIGATOR"
                name="principal_investigator"
                value={formData.principal_investigator}
                onChange={handleChange}
              />
              <span className="ml-2 text-red-500"></span>
            </div>
            <div className="flex">
              <input
                className="shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="contact_no"
                type="text"
                placeholder="CONTACT NUMBER"
                name="contact_no"
                value={formData.contact_no}
                onChange={handleChange}
                required
              />
              <span className="ml-2 text-red-500">*</span>
            </div>
            <div className="flex">
              <input
                className="shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email_id"
                type="email"
                placeholder="EMAIL"
                name="email_id"
                value={formData.email_id}
                onChange={handleChange}
                required
              />
              <span className="ml-2 text-red-500">*</span>
            </div>

            <div className="flex">
              <input
                className="shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="enter_pincode"
                type="text"
                placeholder="PINCODE"
                autoComplete="postal-code"
                name="enter_pincode"
                value={formData.enter_pincode}
                onChange={handleChange}
                required
              />
              <span className="ml-2 text-red-500">*</span>
            </div>

            <div className="flex">
              <input
                className="shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="institute_address"
                type="text"
                placeholder="INSTITUTE ADDRESS"
                name="institute_address"
                value={formData.institute_address}
                onChange={handleChange}
              />
              <span className="ml-2 text-red-500"></span>
            </div>

            <div className="flex">
              <input
                className="shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="institute_name"
                type="text"
                placeholder="INSTITUTE NAME"
                name="institute_name"
                value={formData.institute_name}
                onChange={handleChange}
              />
              <span className="ml-2 text-red-500"></span>
            </div>

            <div className="flex">
              <input
                className="shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="billing_name"
                type="text"
                placeholder="BILLING NAME & ADDRESS"
                name="billing_name"
                value={formData.billing_name}
                onChange={handleChange}
                required
              />
              <span className="ml-2 text-red-500">*</span>
            </div>
          </div>
          <div>
            <div className="flex justify-between">
              <div className="flex">
                <span>Number of Sample/s to be Submitted:</span>
                <select
                  id="noofsample"
                  className="-mt-2 focus:ring-1 focus:ring-blue-500 focus:outline-none focus:border-blue-500 focus:shadow-md border focus:shadow-blue-200 p-2 block m-4 sm:text-sm border-gray-300 rounded-md"
                  name="noofsample"
                  value={noOfSample}
                  onChange={handleSelectChange}

                >
                  <option>SELECT</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                </select>
              </div>
              <div>
                <span className="mr-3">For .Excel Form
                  <a
                    href={csvSample}
                    download="SLS.xls"
                    className="px-2 text-blue-500 rounded inline-block"
                  >
                    <FaDownload />
                  </a></span>
              </div>
            </div>

            <table
              class=" table-repsonsive table table-striped table-bordered display"
              id="item_table"
            >
              <tr className="bg-[#166994] text-white border font-bold">
                <td></td>
                <td colspan="7" className="text-center border border-white p-2">
                  Sample Summary
                </td>
                <td colspan="6" className="text-center border border-white p-2">
                  Primer Information
                </td>
              </tr>

              <tr className="bg-[#166994] text-white">
                <th className={TH_STYLE}>
                  Sample Name(Code)(Same code should be lablled on sample plate){" "}
                </th>
                <th className={TH_STYLE}>Form of Sample</th>
                <th className={TH_STYLE}>Source of Sample</th>
                <th className={TH_STYLE}>Conc(ng/μL)</th>
                <th className={TH_STYLE}>Insert/Product Length (bp)</th>
                <th className={TH_STYLE}>Vector Name(for plasmid)</th>
                <th className={TH_STYLE}>Amount of Sample(μL)</th>
                <th className={TH_STYLE}>Sequencing</th>
                <th className={TH_STYLE}>Farward Primer</th>
                <th className={TH_STYLE}>Reverse Primer</th>
                <th className={TH_STYLE}>Conc(pmol/μL)</th>
                <th className={TH_STYLE}>Ta(°C)</th>
                <th className={TH_STYLE}>Vol(μL)</th>
              </tr>
              {formData.samples.map((sample, index) => (
                <Sample
                  key={index}
                  index={index}
                  sample={sample}
                  onChange={(newSampleData) =>
                    handleSampleChange(index, newSampleData)
                  }
                />
              ))}
            </table>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form1;
