import React, { useState, useContext, useEffect } from 'react';
import { FormContext } from "../Context/FormContext";
import { Spin } from 'antd';

const Additional = () => {
    const formContext = useContext(FormContext);
    // const { formData, handleInputChange, handleSubmit } = formContext;
    const { formData, handleInputChange, handleSubmit: originalHandleSubmit } = formContext;
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Start the spinner
        try {
            await originalHandleSubmit(e);
        } catch (error) {
            console.error('Error submitting the form:', error);
        } finally {
            setLoading(false); // Stop the spinner
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            {loading ? (
                <div className="flex justify-center items-center">
                    <Spin size="large" />
                </div>
            ) : (
                <div className="container mx-auto">
                    <div className="m-5 flex flex-col space-y-6 md:space-y-8 lg:space-y-10">
                        <div class="input-group">
                            <div class="form-group col-md-3 pull-left">
                                <b >Analysis Report :</b>
                                <div className='flex flex-row mt-2'>
                                    <div>
                                        <span className="mt-2 tracking-wider"> Rs.600/- Will be charged<br /> extra for Bioinformatic<br /> analysis Report</span>
                                    </div>
                                    <div className="mx-5 font-bold">
                                        <select
                                            id="analysis_report"
                                            className="form-control block w-full p-2.5 border border-gray-300 bg-white text-gray-700 rounded focus:ring focus:ring-indigo-300 focus:outline-none transition ease-in-out"
                                            value={formData.analysis_report}
                                            onChange={handleInputChange}
                                            name="analysis_report"
                                        >
                                            <option disabled>Select</option>
                                            <option value="Required">Required</option>
                                            <option value="Not Required">Not Required</option>
                                        </select>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div>
                            <h5 className="text-lg font-semibold">Other Instructions from Client:</h5>
                            <textarea
                                name="other_instructions"
                                rows="3"
                                value={formData.other_instructions}
                                onChange={handleInputChange}

                                className="w-full p-2 text-gray-800 border rounded focus:outline-none focus:shadow-outline"
                            ></textarea>
                        </div>

                        <div>
                            <h5 className="text-lg font-semibold">Terms &amp; Conditions</h5>

                            <ul className="list-disc pl-5 text-sm md:text-base">
                                <li> You shall submit Samples to the Laboratory together with duly filled sequencing sample submission form as a hard copy.(Not required if online form is submitted).</li>
                                <li> No samples submitted shall be processed until the duly filled sequencing form received from the client.</li>
                                <li> You understand and acknowledge that there is a risk that all of the Services (or any part thereof) may not deliver the Results that you are seeking and you accept that this is foreseeable given the nature of the Services; for example, by way of an illustrative example only, laboratory testing on DNA that may be inadequate in terms of quantity and/or quality may not deliver the desired Results. You agree that a successful outcome is intrinsically linked to the quality of the Sample as well as other external variable factors that can influence the Results. We shall have no liability whatsoever (howsoever arising) in connection with the Results, including without limitation any defects in the accuracy of the Results, unless it can be demonstrated that such defects in the accuracy of the Results are directly attributable to our negligent acts and/or omissions or breaches of any of the warranties provided by.</li>
                                <li>In the event that you ask us to repeat Services for any reason, you will be required to meet any additional costs associated with such repeat work</li>
                            </ul>
                        </div>

                        <div>
                            <p className="text-sm md:text-base">
                                <strong>For any queries, contact:</strong>
                                <a href="mailto:services@slsresearch.com" className="text-blue-400"> services@slsresearch.com</a>
                            </p>
                            <p className="text-sm md:text-base">
                                <span className="text-red-500 font-bold"> SLS RESEARCH PVT LTD</span><br /><span className="font-bold"></span>#406, Nathubhai Towers, Opp. Dhru Motors, Udhan Main Road, Surat. 394210, Gujarat
                            </p>
                        </div>

                        <div className="mt-4">
                            <p className="text md:text-base">
                                Sample/s submitted to the laboratory? :{' '}
                                <label>
                                    <input
                                        type="radio"
                                        name="samples_submitted"
                                        value="YES"
                                        className="ml-2"
                                        checked={formData.samples_submitted === 'YES'}
                                        onChange={handleInputChange}
                                    /> YES
                                </label>
                                <label>
                                    <input type="radio" checked={formData.samples_submitted === 'NO'}
                                        onChange={handleInputChange} name="samples_submitted" value="NO" className="ml-4" defaultChecked /> NO
                                </label>
                                <span className="ml-2 text-red-500">*</span>
                            </p>
                            <p className="mt-5 text-bold md:text-base">
                                <input type="checkbox" name="terms" required className="mr-2" />
                                I accept Terms and Conditions.<span className="ml-2 text-red-500">*</span> {' '}

                                <a href="#" className="text-blue-400">Guidelines for Sequencing Sample Submission</a>

                            </p>
                            <div class="mt-3 w-11/12 sm:w-11/12 md:w-11/12 lg:w-1/12 flex justify-start">
                                <input
                                    type="submit"
                                    class="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition"
                                    value="Submit"
                                    id="submit5"
                                    name="submit2"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </form >
    )
}

export default Additional