import client from "./client";

export const addForm = async (obj) => {
  try {
    const { data } = await client.post(`/form/apply`, obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
