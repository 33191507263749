import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_ENCRYPTION_SECRET; // Replace with a strong secret key

export const encryptRole = (role) => {
  const encryptedRole = CryptoJS.AES.encrypt(role, secretKey).toString();
  return encryptedRole;
};

export const decryptRole = (encryptedRole) => {
  try {
    if (!encryptedRole) return null;
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedRole, secretKey);
    const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
    return decryptedText;
  } catch (error) {
    console.error("Error decrypting role:", error);
    return null; // or handle the error accordingly
  }
};
