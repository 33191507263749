import React, { useState, useEffect, useContext } from "react";
import "tailwindcss/tailwind.css";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { getData } from "../api/dashboard";
import DynamicTable from "./DynamicTable";
import { Table, Modal } from "antd";
import FormEdit from "./FormEdit";
import { FormContext } from "../Context/FormContext";

const Dashboard = () => {

  let navigate = useNavigate();

  const [tableData, setTableData] = useState();
  const [modalProductId, setModalProductId] = useState(null);
  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState(false);

  const formContext = useContext(FormContext);
  const { getRole } = formContext;

  // POPUP REQUIREMENTS  !
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const getFormData = async () => {
      try {
        const data = await getData();
        console.log(data, "111");
        setTableData(data.results || []);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    getFormData();
  }, []);

  useEffect(() => {
    if (getRole() !== "admin") {
      navigate("/form");
    }
  }, [navigate]);


  useEffect(() => {
    if (formData.lead_id) {
      setTableData((prevData) =>
        prevData.map((item) =>
          item.lead_id === formData.lead_id ? { ...item, ...formData } : item
        )
      );
    }
  }, [formData]);

  return (
    <>
      <section class="p-4 mx-10">
        <Toaster />
        <Modal
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{
            style: { backgroundColor: "blue", color: "white" },
          }}
          width={1000}
        >
          <FormEdit formData={formData} setFormData={setFormData} productId={modalProductId} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
        </Modal>

        <div className="rounded bg-white">
          <DynamicTable
            data={tableData}
            setTableData={setTableData}
            setIsModalVisible={setIsModalVisible}
            showModal={showModal}
            handleCancel={handleCancel}
            setModalProductId={setModalProductId}
          />
        </div>
      </section>
    </>
  );
};

export default Dashboard;
