import React, { useState, useEffect } from "react";

const disableFieldsObj = {
  ["Microbial Culture"]: [
    "concNgPerUl",
    "insertLengthBp",
    "vectorName",
    "amountOfSampleUl",
  ],
};
const allFields = [
  "concNgPerUl",
  "insertLengthBp",
  "vectorName",
  "amountOfSampleUl",
  "formOfSample",
  "sourceOfSample",
  "sequencing",
  "forwardPrimer",
  "reversePrimer",
  "volUl",
  "taDegreesC",
  "concPmolPerUl",
];

const Sample = ({ sample, onChange, index }) => {
  const [sampleInfo, setSampleInfo] = useState(sample);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSampleInfo({ ...sampleInfo, [name]: value });
  };
  useEffect(() => {
    if (Object.keys(disableFieldsObj).includes(sampleInfo.formOfSample)) {
      const fieldsToDisable = disableFieldsObj[sampleInfo.formOfSample];
      fieldsToDisable.forEach((id) => {
        document.getElementById(id + index).disabled = true;
      });
      allFields.forEach((id) => {
        if (!fieldsToDisable.includes(id)) {
          document.getElementById(id + index).disabled = false;
        }
      });
    } else {
      allFields.forEach((id) => {
        document.getElementById(id + index).disabled = false;
      });
    }
    onChange(sampleInfo);
  }, [sampleInfo]);

  return (
    <tr className="border border-gray-300">
      <td className="p-2">
        <input
          type="text"
          name="sampleName"
          id={"sampleName" + index}
          value={sampleInfo.sampleName}
          onChange={handleChange}
          placeholder="Sample Name(Code)"
          required
          className="focus:ring-1 focus:ring-blue-500 focus:outline-none focus:border-blue-500 focus:shadow-md border focus:shadow-blue-200 p-2 block w-full sm:text-sm border-gray-300 rounded-md"
        />
      </td>
      <td className="p-2">
        <select
          name="formOfSample"
          id={"formOfSample" + index}
          value={sampleInfo.formOfSample}
          onChange={handleChange}
          required
          className="focus:ring-1 focus:ring-blue-500 focus:outline-none focus:border-blue-500 focus:shadow-md border focus:shadow-blue-200 p-2 block w-full sm:text-sm border-gray-300 rounded-md"
        >
          <option></option>
          <option value="Animal Tissue">Animal Tissue</option>
          <option value="Microbial Culture">Microbial Culture</option>
          <option value="Genomic DNA">Genomic DNA</option>
          <option value="Un-purified PCR Product">
            Un-purified PCR Product
          </option>
          <option value="Purified PCR Product">Purified PCR Product</option>
          <option value="Purified Plasmid">Purified Plasmid</option>
          <option value="Plant Seeds">Plant Seeds</option>
          <option value="Plant Leaves">Plant Leaves</option>
          <option value="Any Other">Any Other</option>
        </select>
      </td>
      <td className="p-2">
        <select
          name="sourceOfSample"
          id={"sourceOfSample" + index}
          value={sampleInfo.sourceOfSample}
          onChange={handleChange}
          required
          className="focus:ring-1 focus:ring-blue-500 focus:outline-none focus:border-blue-500 focus:shadow-md border focus:shadow-blue-200 p-2 block w-full sm:text-sm border-gray-300 rounded-md"
        >
          <option></option>
          <option value="FISH">FISH</option>
          <option value="Bacteria">Bacteria</option>
          <option value="Fungi">Fungi</option>
          <option value="Algae">Algae</option>
          <option value="Actinomycetes">Actinomycetes</option>
          <option value="Yeast">Yeast</option>
          <option value="Plants">Plants</option>
          <option value="Insect">Insect</option>
          <option value="Virus">Virus</option>
          <option value="Others">Others</option>
        </select>
      </td>
      <td className="p-2">
        <input
          type="text"
          name="concNgPerUl"
          id={"concNgPerUl" + index}
          value={sampleInfo.concNgPerUl}
          onChange={handleChange}
          placeholder="Conc(ng/μL)"
          className="focus:ring-1 focus:ring-blue-500 focus:outline-none focus:border-blue-500 focus:shadow-md border focus:shadow-blue-200 p-2 block w-full sm:text-sm border-gray-300 rounded-md"
        />
      </td>
      <td className="p-2">
        <input
          type="text"
          name="insertLengthBp"
          id={"insertLengthBp" + index}
          value={sampleInfo.insertLengthBp}
          onChange={handleChange}
          placeholder="Insert/Product Length (bp)"
          className="focus:ring-1 focus:ring-blue-500 focus:outline-none focus:border-blue-500 focus:shadow-md border focus:shadow-blue-200 p-2 block w-full sm:text-sm border-gray-300 rounded-md"
        />
      </td>
      <td className="p-2">
        <input
          type="text"
          name="vectorName"
          id={"vectorName" + index}
          value={sampleInfo.vectorName}
          onChange={handleChange}
          placeholder="Vector Name(for plasmid)"
          className="focus:ring-1 focus:ring-blue-500 focus:outline-none focus:border-blue-500 focus:shadow-md border focus:shadow-blue-200 p-2 block w-full sm:text-sm border-gray-300 rounded-md"
        />
      </td>
      <td className="p-2">
        <input
          type="text"
          name="amountOfSampleUl"
          id={"amountOfSampleUl" + index}
          value={sampleInfo.amountOfSampleUl}
          onChange={handleChange}
          placeholder="Amount of Sample(μL)"
          className="focus:ring-1 focus:ring-blue-500 focus:outline-none focus:border-blue-500 focus:shadow-md border focus:shadow-blue-200 p-2 block w-full sm:text-sm border-gray-300 rounded-md"
        />
      </td>
      <td className="p-2">
        <select
          name="sequencing"
          required
          id={"sequencing" + index}
          value={sampleInfo.sequencing}
          onChange={handleChange}
          className="focus:ring-1 focus:ring-blue-500 focus:outline-none focus:border-blue-500 focus:shadow-md border focus:shadow-blue-200 p-2 block w-full sm:text-sm border-gray-300 rounded-md"
        >
          <option value=""></option>
          <option value="Unidirectional">Unidirectional</option>

          <option value="Bi-directional">Bi-directional</option>

          <option value="Primer Walking - Unidirectional">
            Primer Walking - Unidirectional
          </option>

          <option value="Primer Walking - Bi-Directional">
            Primer Walking - Bi-Directional
          </option>

          <option value="Meta Genomic service ">Meta Genomic service </option>

          <option value="DNA Barcoding ">DNA Barcoding </option>

          <option value="ISSR service">ISSR service</option>

          <option value="SSR Service">SSR Service</option>
        </select>
      </td>
      <td className="p-2">
        <input
          type="text"
          name="forwardPrimer"
          id={"forwardPrimer" + index}
          value={sampleInfo.forwardPrimer}
          onChange={handleChange}
          placeholder="Forward Primer"
          className="focus:ring-1 focus:ring-blue-500 focus:outline-none focus:border-blue-500 focus:shadow-md border focus:shadow-blue-200 p-2 block w-full sm:text-sm border-gray-300 rounded-md"
        />
      </td>
      <td className="p-2">
        <input
          type="text"
          name="reversePrimer"
          id={"reversePrimer" + index}
          value={sampleInfo.reversePrimer}
          onChange={handleChange}
          placeholder="Reverse Primer"
          className="focus:ring-1 focus:ring-blue-500 focus:outline-none focus:border-blue-500 focus:shadow-md border focus:shadow-blue-200 p-2 block w-full sm:text-sm border-gray-300 rounded-md"
        />
      </td>
      <td className="p-2">
        <input
          type="text"
          name="concPmolPerUl"
          id={"concPmolPerUl" + index}
          value={sampleInfo.concPmolPerUl}
          onChange={handleChange}
          placeholder="Conc(pmol/μL)"
          className="focus:ring-1 focus:ring-blue-500 focus:outline-none focus:border-blue-500 focus:shadow-md border focus:shadow-blue-200 p-2 block w-full sm:text-sm border-gray-300 rounded-md"
        />
      </td>
      <td className="p-2">
        <input
          type="text"
          name="taDegreesC"
          id={"taDegreesC" + index}
          value={sampleInfo.taDegreesC}
          onChange={handleChange}
          placeholder="Ta(°C)"
          className="focus:ring-1 focus:ring-blue-500 focus:outline-none focus:border-blue-500 focus:shadow-md border focus:shadow-blue-200 p-2 block w-full sm:text-sm border-gray-300 rounded-md"
        />
      </td>
      <td className="p-2">
        <input
          type="text"
          name="volUl"
          id={"volUl" + index}
          value={sampleInfo.volUl}
          onChange={handleChange}
          placeholder="Vol(μL)"
          className="focus:ring-1 focus:ring-blue-500 focus:outline-none focus:border-blue-500 focus:shadow-md border focus:shadow-blue-200 p-2 block w-full sm:text-sm border-gray-300 rounded-md"
        />
      </td>
    </tr>
  );
};

export default Sample;
