import React, { useState, useEffect, useContext } from "react";
import { FaEdit, FaSave } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Table } from "antd";
import { Toaster, toast } from "react-hot-toast";
import { updateRecords, getSingleData } from "../api/dashboard";
const FORM_GRID_STYLE = "grid grid-cols-1 md:grid-cols-2 md:gap-4";

const FormEdit = ({ productId, setIsModalVisible, setFormData, formData }) => {

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSingleData(productId);
        setFormData(data.results[0] || []);
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };
    fetchData();
  }, [productId]);


  const [inEditBool, setInEditBool] = useState(false);


  const handleChange = (e, field) => {

    let newValue;
    if (field === "payment_status") {
      // newValue = e.target.value === "PAID" ? 1 : 0;
      newValue = parseInt(e.target.value, 10);
      console.log(newValue, "newValue1");
    } else {
      newValue = e.target.value;
      console.log(newValue, "newValue2");
    }

    // setFormData({
    //   ...formData,
    //   // [field]: e.target.value,
    //   [field]: newValue,
    //   ["payment_status"]: newValue,
    // });
    setFormData((prevState) => ({
      ...prevState,
      // [field]: e.target.value,
      [field]: newValue,
    }));
  };

  const handleEditProduct = () => {
    setInEditBool(true);
  };

  const handleSaveProduct = async (productId) => {
    setInEditBool(false);
    const result = await updateRecords(productId, formData);
    if (result.success) {
      toast.success("Data updated successfully");
      setIsModalVisible(false);
    } else {
      toast.error("Data update failed");
    }
  };

  return (
    <div className="w-full">
      <Toaster />
      <div className="text-center container mx-auto h-full w-full">
        <div className="bg-white p-4 rounded-lg max-h-4/5 mx-auto">
          <div className="bg-white">
            <div className="flex items-center justify-center">
              <h1 className="text-2xl m-1 p-1 font-bold text-[#166994]">FormDetails</h1>
            </div>
            <div className="mt-5 w-full mx-auto">
              {formData && (
                <div>
                  <div
                    key={formData.lead_id}
                    className={
                      FORM_GRID_STYLE +
                      " grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 "
                    }
                  >
                    <div class="mb-5">
                      <label
                        for="base-input"
                        class="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-black"
                      >
                        Payment
                      </label>
                      <input
                        type="text"
                        id="base-input"
                        onChange={(e) => handleChange(e, "payment")}
                        disabled={!inEditBool}
                        value={formData.payment}
                        class="border  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                    <div class="mb-5">
                      <label
                        for="base-input"
                        class="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-black"
                      >
                        Payment Status
                      </label>

                      {inEditBool ? (
                        <select
                          id="base-input"
                          onChange={(e) => handleChange(e, "payment_status")}
                          value={formData.payment_status}
                          class="border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option disabled>Select</option>
                          <option value="0">NOT PAID</option>
                          <option value="1">PAID</option>
                        </select>
                      ) : (
                        <input
                          type="text"
                          id="base-input"
                          onChange={(e) =>
                            handleChange(e, "payment_status")
                          }
                          disabled={!inEditBool}
                          // disabled={!inEditBool}
                          value={String(formData.payment_status) === "1" ? "PAID" : "NOT PAID"}
                          class="border  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5    dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      )}

                    </div>
                    <div class="mb-5">
                      <label
                        for="base-input"
                        class="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-black"
                      >
                        Remarks
                      </label>
                      <input
                        type="text"
                        id="base-input"
                        onChange={(e) => handleChange(e, "remarks")}
                        disabled={!inEditBool}
                        value={formData.remarks}
                        class="border  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>

                  </div>
                  <div class="mb-5">
                    {!inEditBool ? (
                      <button
                        onClick={handleEditProduct}
                        className="bg-blue-500 text-white p-3 gap-2 text-md rounded-md w-full flex justify-center items-center font-semibold md:w-auto hover:bg-blue-600"
                      >
                        Edit <FaEdit />
                      </button>
                    ) : (
                      <button
                        onClick={() => handleSaveProduct(formData.lead_id)}
                        className="bg-green-500 text-white p-3 gap-2 text-md rounded-md w-full flex justify-center items-center font-semibold md:w-auto hover:bg-green-600"
                      >
                        Save
                        <FaSave />
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEdit;
