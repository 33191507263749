import React from 'react';

const Footer = () => {
    return (
        <footer className="text-black py-6 bg-gray-200">
            <div className="container mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 px-4">

                    <div>
                        <h3 className="text-lg font-semibold">ISO Certification</h3>

                        <p>ISO 9001:2015 Registered</p>
                        <div className="mt-4 flex space-x-4">
                            <a href="#" className="text-blue-600">
                                <i className="fa fa-facebook"></i>
                            </a>
                            <a href="#" className="text-blue-400">
                                <i className="fa fa-twitter"></i>
                            </a>
                            <a href="#" className="text-blue-700">
                                <i className="fa fa-linkedin"></i>
                            </a>
                        </div>
                    </div>


                    <div>
                        <h3 className="text-gray-500 text-lg">About</h3>
                        <ul className="space-y-2">
                            <li><a href="#">Company Profile</a></li>
                            <li><a href="#">Mission Statement</a></li>
                            <li><a href="#">Achievements</a></li>
                            <li><a href="#" className="hover:underline">Our Customers</a></li>
                            <li><a href="#" className="hover:underline">Resources</a></li>
                        </ul>
                    </div>

                    <div>
                        <h3 className="text-gray-500 text-lg">Service</h3>
                        <ul className="space-y-2">
                            <li><a href="#" className="hover:underline">DNA sequencing</a></li>
                            <li><a href="#" className="hover:underline">Plasmid Purification &amp; Amplification</a></li>
                            <li><a href="#" className="hover:underline">Custom DNA/RNA Isolation Services</a></li>
                            <li><a href="#" className="hover:underline">SDS-PAGE analysis of Proteins</a></li>
                            <li><a href="#" className="hover:underline">Oligo Synthesis</a></li>
                        </ul>
                    </div>

                    <div>
                        <h3 className="text-gray-500 text-lg">Products</h3>
                        <ul className="space-y-2">
                            <li><a href="#" className="hover:underline">Genomics Products</a></li>
                            <li><a href="#" className="hover:underline">Powdered Media</a></li>
                            <li><a href="#" className="hover:underline">Proteomic Products</a></li>
                            <li><a href="#" className="hover:underline">Teaching Kits</a></li>
                            <li><a href="#" className="hover:underline">DNA Purification Kits</a></li>
                        </ul>
                    </div>
                </div>

                <div className="text-center mt-6">
                    <p>Copyright © 2024 SLS Research Pvt Ltd. All Rights Reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
