import React, { createContext, useState } from "react";
import { addForm } from "../api/form";
import { Toaster, toast } from "react-hot-toast";
import { decryptRole } from "../Authentication/EncryptionUtils";

import * as EmailValidator from "email-validator";

const FormContext = createContext();

const FormProvider = ({ children }) => {
    const [formData, setFormData] = useState({
        clientname: "",
        principal_investigator: "",
        contact_no: "",
        email_id: "",
        billing_name: "",
        institute_name: "",
        enter_pincode: "",
        institute_address: "",
        samples: [],
        primers: [],
        analysis_report: "Required",
        other_instructions: "",
        samples_submitted: "no",
    });


    const [primers, setPrimers] = useState([
        {
            PrimerName: '',
            Sequence: '',
            Synthesized: 'YES',
            selected: false
        },
    ]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const fieldValue = type === 'checkbox' ? checked : value;
        setFormData((prev) => ({ ...prev, [name]: fieldValue }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.contact_no < 10) {
            toast.error("Please Enter 10 digit number for Contact Number.");
            return;
        }
        if (formData.enter_pincode < 6) {
            toast.error("Please Enter 6 digit number for Pincode.");
            return;
        }

        if (formData.email_id) {
            if (!EmailValidator.validate(formData.email_id)) {
                toast.error("Invalid Email ID !");
                return;
            }
        }

        // Merge the `samples` array into the `formData` object
        const updatedFormData = { ...formData, primers };

        if (formData.clientname === "" || formData.contact_no === "" || formData.email_id === "" || formData.enter_pincode === "" || formData.billing_name === "") {
            toast.error("Please fill all the fields");
            return;
        }

        // Call the `addForm` API with the updated `formData`
        const response = await addForm(updatedFormData);
        if (response.success) {
            console.log("Form submission successful:", response.msg);
            setFormData({
                clientname: "",
                principal_investigator: "",
                contact_no: "",
                email_id: "",
                billing_name: "",
                institute_name: "",
                enter_pincode: "",
                institute_address: "",
                samples: [],
                primers: [],
                analysis_report: "",
                other_instructions: "",
                samples_submitted: "",
            });
            setPrimers([
                {
                    PrimerName: '',
                    Sequence: '',
                    Synthesized: 'YES',
                    selected: false
                },
            ]);
            toast.success("Form submitted successfully");
        } else {
            console.log("Form submission failed:");
        }
    }


    const getRole = () => {
        if (localStorage.getItem("role"))
            return decryptRole(localStorage.getItem("role"));
        else return null;
    };

    // State to store the role
    const [role, setRole] = useState(getRole());

    const contextValues = {
        role,
        getRole,
        formData,
        setFormData,
        primers,
        setPrimers,
        handleSubmit,
        handleInputChange,
    };
    return (
        <FormContext.Provider value={contextValues}>
            {children}
        </FormContext.Provider>
    );
};

export { FormContext, FormProvider };
