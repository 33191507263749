import React, { useState, useEffect, useContext } from "react";
import "tailwindcss/tailwind.css";
import { Toaster, toast } from "react-hot-toast";
import moment from "moment";
import { MdAutoDelete } from "react-icons/md";
import { deleteRecord, getData } from "../api/dashboard";
import { CiEdit } from "react-icons/ci";

import { Table, Modal, Popover } from "antd";
import { Details } from "./Details";

import Popup from "./Popup";

import { decryptRole } from "../Authentication/EncryptionUtils";

// import Sample1 from "../Form/Sample1";

const TH_STYLE = "border border-white p-2";

const DynamicTable = ({ showModal, setModalProductId, data, setTableData }) => {
  const [sortedInfo, setSortedInfo] = useState({});

  console.log("Data", data);

  // POPUP REQUIREMENTS  !
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const [formId, setFormId] = useState(0);
  const showModal1 = (id) => {
    setIsModalVisible1(true);
    setFormId(id);
  };

  const handleOk1 = () => {
    setIsModalVisible1(false);
  };

  const handleCancel1 = () => {
    setIsModalVisible1(false);
  };

  //For Searching...
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleChange = (sorter) => {
    setSortedInfo(sorter);
  };

  const handleDelete = async (id) => {
    const result = window.confirm(
      "Are you sure you want to delete this record?"
    );
    if (result) {
      const data = await deleteRecord(id);
      if (data.success) {
        toast.success("Record Deleted Successfully");
        const newData = await getData();

        setTableData(newData.results || []);
      } else {
        toast.error("Delete is not implemented yet");
      }
    }
  };

  const headerStyle = {
    color: "white",
    textAlign: "center",
    fontWeight: "semibold",
    fontSize: "16px",
    backgroundColor: "#0097A7",
  };

  const filteredData = Array.isArray(data)
    ? data.filter((record) => {
      // Check all relevant fields in the record
      return Object.values(record).some((value) => {
        if (typeof value === "string") {
          return value.toLowerCase().includes(searchQuery.toLowerCase());
        } else if (moment.isMoment(value)) {
          // If it's a date, convert it to string before checking
          return value
            .format("DD-MM-YYYY")
            .includes(searchQuery.toLowerCase());
        } else {
          return false;
        }
      });
    })
    : [];

  const columns = [
    {
      title: "S.No",
      dataIndex: "id",
      align: "center",
      key: "id",
      // width: 120,
      onHeaderCell: () => ({
        style: headerStyle,
      }),
      render: (text, record, index) => {
        return (
          <div className="bg-transparent font-semibold border-r -mr-4">
            <span
              className="cursor-pointer"
              onClick={() => showModal1(record.lead_id)}
            >
              {index + 1}
            </span>
          </div>
        );
      },
      className:
        "custom-header-class border border-slate-300 hover:bg-green-100",
    },
    {
      title: "Lead ID",
      dataIndex: "lead_id",
      align: "center",
      key: "lead_id",
      onHeaderCell: () => ({
        style: headerStyle,
      }),
      render: (text, record, index) => {
        return (
          <div className="bg-transparent font-semibold border-r -mr-4">
            <span
              className="cursor-pointer"
              onClick={() => showModal1(record.lead_id)}
            >
              {record.lead_id}
            </span>
          </div>
        );
      },
      className:
        "custom-header-class border border-slate-300 hover:bg-green-100",
    },
    {
      title: "Date",
      dataIndex: "created_at",
      align: "center",
      key: "created_at",
      width: 50,
      onHeaderCell: () => ({
        style: headerStyle,
      }),
      render: (text, record, index) => {
        return (
          <div className="bg-transparent font-semibold border-r -mr-4">
            {moment(record.created_at).format("DD-MM-YYYY")}
          </div>
        );
      },
      className: "custom-header-class  border border-slate-300",
    },
    {
      title: "Client Name",
      dataIndex: "clientname",
      align: "center",
      key: "clientname",
      width: 150,
      onHeaderCell: () => ({
        style: headerStyle,
      }),
      render: (text, record, index) => {
        return (
          <div className="bg-transparent font-semibold border-r -mr-4">
            {record.clientname}
          </div>
        );
      },
      className:
        "cursor-pointer custom-header-class border border-slate-300 hover:bg-green-100",
    },
    {
      title: "Principal Investigator",
      dataIndex: "principal_investigator",
      align: "center",
      key: "principal_investigator",
      // width: 150,
      onHeaderCell: () => ({
        style: headerStyle,
      }),
      render: (text, record, index) => {
        return (
          <div className="bg-transparent font-semibold border-r -mr-4">
            {record.principal_investigator}
          </div>
        );
      },
      className: "custom-header-class  border border-slate-300",
    },
    {
      title: "Contact No",
      dataIndex: "contact_no",
      align: "center",
      key: "contact_no",
      // width: 120,
      onHeaderCell: () => ({
        style: headerStyle,
      }),
      render: (text, record, index) => {
        return (
          <div className="bg-transparent font-semibold border-r -mr-4">
            {record.contact_no}
          </div>
        );
      },
      className: "custom-header-class  border border-slate-300",
    },
    {
      title: "Email",
      dataIndex: "email_id",
      align: "center",
      key: "email_id",
      // width: 120,
      onHeaderCell: () => ({
        style: headerStyle,
      }),
      render: (text, record, index) => {
        return (
          <div className="bg-transparent font-semibold border-r -mr-4">
            {record.email_id}
          </div>
        );
      },
      className: "custom-header-class  border border-slate-300",
    },
    {
      title: "Billing Name",
      dataIndex: "billing_name",
      align: "center",
      key: "billing_name",
      // width: 50,
      onHeaderCell: () => ({
        style: headerStyle,
      }),
      render: (text, record, index) => {
        const feedback = record.billing_name;
        const shortFeedback =
          feedback.length > 20 ? feedback.substring(0, 20) + "..." : feedback;
        return (
          <Popover content={<Popup feedBack={feedback} />}>
            <div
              className="bg-transparent -mr-4 font-semibold border-r"
              title={feedback}
            >
              {shortFeedback}
            </div>
          </Popover >
        );
      },
      className: "custom-header-class  border border-slate-300",
    },
    {
      title: "Institute Name",
      dataIndex: "institute_name",
      align: "center",
      key: "Institute Name",
      // width: 50,
      onHeaderCell: () => ({
        style: headerStyle,
      }),
      render: (text, record, index) => {
        const feedback = record.institute_name;
        const shortFeedback =
          feedback.length > 20 ? feedback.substring(0, 20) + "..." : feedback;
        return (

          <Popover content={<Popup feedBack={feedback} />}>
            <div
              className="bg-transparent -mr-4 font-semibold border-r"
              title={feedback}
            >
              {shortFeedback}
            </div>
          </Popover >
        );
      },
      className: "custom-header-class  border border-slate-300",
    },
    {
      title: "Other Instructions",
      dataIndex: "other_instructions",
      align: "center",
      key: "other_instructions",
      // width: 50,
      onHeaderCell: () => ({
        style: headerStyle,
      }),
      render: (text, record, index) => {
        return (
          <div className="bg-transparent font-semibold border-r -mr-4">
            {record.other_instructions}
          </div>
        );
      },
      className: "custom-header-class  border border-slate-300",
    },
    {
      title: "Payment",
      dataIndex: "payment",
      align: "center",
      key: "payment",
      // width: 30,
      onHeaderCell: () => ({
        style: headerStyle,
      }),
      render: (text, record, index) => {
        return (
          <div className="bg-transparent font-semibold border-r">
            {record.payment}
          </div>
        );
      },
      className: "custom-header-class  border border-slate-300",
    },
    {
      title: "Payment Status",
      dataIndex: "payment_status",
      align: "center",
      key: "payment_status",
      // width: 50,
      onHeaderCell: () => ({
        style: headerStyle,
      }),
      render: (text, record, index) => {
        return (
          <div className="bg-transparent font-semibold border-r">
            {String(record.payment_status) === "1" ? "PAID" : "NOT PAID"}
          </div>
        );
      },
      className: "custom-header-class  border border-slate-300",
    },
    {
      title: "Analysis Report",
      dataIndex: "analysis_report",
      align: "center",
      key: "analysis_report",
      // width: 30,
      onHeaderCell: () => ({
        style: headerStyle,
      }),
      render: (text, record, index) => {
        return (
          <div className="bg-transparent font-semibold border-r">
            {record.analysis_report}
          </div>
        );
      },
      className: "custom-header-class  border border-slate-300",
    },
    {
      title: "Samples Submitted",
      dataIndex: "samples_submitted",
      align: "center",
      key: "samples_submitted",
      // width: 50,
      onHeaderCell: () => ({
        style: headerStyle,
      }),
      render: (text, record, index) => {
        return (
          <div className="bg-transparent font-semibold border-r">
            {record.samples_submitted}
          </div>
        );
      },
      className: "custom-header-class  border border-slate-300",
    },

    {
      title: "Delete",
      dataIndex: "delete",
      align: "center",
      key: "delete",
      // width: 120,
      hidden: decryptRole(localStorage.getItem("role")) !== "admin",
      onHeaderCell: () => ({
        style: headerStyle,
      }),
      render: (text, record, index) => {
        return (
          <div
            className="bg-transparent font-semibold rounded-lg text-center flex items-center justify-center"
            style={{ height: "100%" }}
          >
            <MdAutoDelete
              onClick={() => handleDelete(record.lead_id)}
              className={` hover:scale-105 transition-all duration-100 ease-in-out cursor-pointer size-5 text-red-500`}
            />
          </div>
        );
      },
      className:
        "custom-header-class border border-slate-300 hover:bg-green-100",
    },
    {
      title: "Edit",
      dataIndex: "Edit",
      align: "center",
      key: "Edit",
      // width: 120,
      hidden: decryptRole(localStorage.getItem("role")) !== "admin",
      onHeaderCell: () => ({
        style: headerStyle,
      }),
      render: (text, record, index) => {
        return (
          <div className="bg-transparent font-semibold border-r -mr-4">
            <div
              className="bg-transparent rounded-lg text-center flex items-center justify-center"
              style={{ height: "100%" }}
            >
              <CiEdit
                onClick={() => {
                  setModalProductId(record.lead_id);
                  showModal();
                }}
                className={` hover:scale-105 transition-all duration-100 ease-in-out cursor-pointer size-5 text-green-500`}
              />
            </div>
          </div>
        );
      },
      className: "custom-header-class  border border-slate-300",
    },
  ];

  const parsedData = filteredData.map((item) => {
    return {
      ...item, // Retain all other fields
      primers: JSON.parse(item.primers), // Parse the primers string
      samples: JSON.parse(item.samples), // Parse the samples string
    };
  });

  return (
    <div>
      <Modal
        open={isModalVisible1}
        onOk={handleOk1}
        onCancel={handleCancel1}
        okButtonProps={{
          style: {
            backgroundColor: "blue",
            color: "white",
          },
        }}
        width={1700}
      >
        {/* <Details parsedData={parsedData} productId={modalProductId} /> */}
        <Details parsedData={parsedData} formId={formId} />
      </Modal>

      {/* Search Query */}
      <div className="mt-10 mb-5">
        <div className="mx-5">
          <div className="text-primary-text flex gap-3 rounded-lg flex-row-reverse py-3 px-2 bg-surface-light">
            <div className="relative w-full">
              <input
                type="text"
                className="border-none bg-transparent focus:outline-none focus:ring-0 w-full p-0 body2"
                id="Global search Container"
                autoComplete="off"
                placeholder="Searching ...."
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="w-6 h-6 text-primary-text/60"
            >
              <circle cx="11" cy="11" r="8"></circle>
              <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
            </svg>
          </div>
        </div>
      </div>

      {/* Dynamic Table */}
      {filteredData.length > 0 ? (
        <Table
          dataSource={filteredData}
          columns={columns}
          scroll={{ x: true }}
          onChange={handleChange}
        />
      ) : (
        <p>No data to display.</p>
      )}
    </div>
  );
};
export default DynamicTable;
