import React, { useState, useContext, useEffect } from 'react';
import { FormContext } from "../Context/FormContext";

const Form2 = () => {
    const formContext = useContext(FormContext);
    const { primers, setPrimers } = formContext;


    // Handle change in individual sample fields
    const handleSampleChange = (index, name, value) => {
        const updatedprimers = [...primers];
        updatedprimers[index] = { ...updatedprimers[index], [name]: value };
        setPrimers(updatedprimers);
    };

    // Add a new row
    const addRow = () => {
        const newSample = { PrimerName: '', Sequence: '', Synthesized: 'YES', selected: false };
        setPrimers([...primers, newSample]);
    };

    // Delete selected rows
    const deleteRows = () => {
        setPrimers(primers.filter((sample) => !sample.selected));
    };

    return (
        <div className="p-5 mx-auto overflow-x-auto">
            <table className="table-auto w-full border border-gray-300">
                <thead className="bg-gray-200">
                    <tr>
                        <th className="p-2 text-center">
                            <input
                                type="checkbox"
                                onChange={(e) => {
                                    const checked = e.target.checked;
                                    const updatedprimers = primers.map((sample) => ({
                                        ...sample,
                                        selected: checked,
                                    }));
                                    setPrimers(updatedprimers);
                                }}
                            />
                        </th>
                        <th className="p-2">Primer Name</th>
                        <th className="p-2">5' --Sequence-- 3'</th>
                        <th className="p-2">Primer to be Synthesized (Yes/No)</th>
                    </tr>
                </thead>
                <tbody>
                    {primers.map((sample, index) => (
                        <tr key={index}>
                            <td className="p-2 text-center">
                                <input
                                    type="checkbox"
                                    checked={sample.selected}
                                    onChange={(e) => {
                                        const checked = e.target.checked;
                                        handleSampleChange(index, 'selected', checked);
                                    }}
                                />
                            </td>
                            <td className="p-2">
                                <input
                                    type="text"
                                    name="PrimerName"
                                    value={sample.PrimerName}
                                    onChange={(e) => handleSampleChange(index, 'PrimerName', e.target.value)}
                                    className="shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                            </td>
                            <td className="p-2">
                                <input
                                    type="text"
                                    name="Sequence"
                                    value={sample.Sequence}
                                    onChange={(e) => handleSampleChange(index, 'Sequence', e.target.value)}
                                    className="shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                            </td>
                            <td className="p-2">
                                <select
                                    name="Synthesized"
                                    value={sample.Synthesized}
                                    onChange={(e) => handleSampleChange(index, 'Synthesized', e.target.value)}
                                    className="shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                >
                                    <option value="YES">YES</option>
                                    <option value="NO">NO</option>
                                </select>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="flex flex-row w-full sm:w-1/4 md:w-1/4 lg:w-1/4 gap-2 mt-3">
                <button
                    className="bg-green-500 text-white p-2 rounded-md hover:bg-green-600 transition"
                    type="button"
                    onClick={addRow}
                >
                    + Add More
                </button>
                <button
                    className="bg-red-500 text-white p-2 rounded-md hover:bg-red-600 transition"
                    type="button"
                    onClick={deleteRows}
                >
                    - Delete
                </button>
            </div>
        </div>
    );
};

export default Form2;
